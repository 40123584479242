// user management
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const REGISTER_USER = 'REGISTER_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOG_OUT_MERCHANT = 'LOG_OUT_MERCHANT';
export const LOG_OUT_USER = 'LOG_OUT_USER';
export const LOGIN_MERCHANT_WITH_STORAGE_DATA = 'LOGIN_MERCHANT_WITH_STORAGE_DATA';
export const LOGIN_USER_WITH_STORAGE_DATA = 'LOGIN_USER_WITH_STORAGE_DATA';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const CHANGE_MODE = 'CHANGE_MODE';
export const UPDATE_TYPE = 'UPDATE_TYPE';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const SET_IS_FETCHING = 'SET_IS_FETCHING';
export const SET_IS_FETCHING_DATA = 'SET_IS_FETCHING_DATA';
export const SET_PRODUCT_CATEGORIES = 'SET_PRODUCT_CATEGORIES';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_SHIPPING = 'SET_SHIPPING';
export const SET_MERCHANT = 'SET_MERCHANT';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const SET_TAB_INDEX = 'SET_TAB_INDEX';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const SET_ACTIVE_CATEGORY = 'SET_ACTIVE_CATEGORY';
export const SET_CART_DATA = 'SET_CART_DATA';
export const CLEAR_CART_DATA = 'CLEAR_CART_DATA';
export const SET_CART_PRODUCT_DETAIL = 'SET_CART_PRODUCT_DETAIL';
export const ADD_TO_PRODUCTS = 'ADD_TO_PRODUCTS';
export const SET_FOCUS_PRODUCT = 'SET_FOCUS_PRODUCT';
export const UPDATE_FOCUS_PRODUCT = 'UPDATE_FOCUS_PRODUCT';
export const LOGIN_STORE_USER = 'LOGIN_STORE_USER';
export const REGISTER_STORE_USER = 'REGISTER_STORE_USER';
export const SET_STORE_USER_PROFILE = 'SET_STORE_USER_PROFILE';
export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';
export const SET_PAYMENT_ERROR_STATUS = 'SET_PAYMENT_ERROR_STATUS';
export const SET_FAVORITES = 'SET_FAVORITES';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const CLEAR_FILTERS = 'UPDATE_FILTERS';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const SET_FOCUS_TRANSACTION = 'SET_FOCUS_TRANSACTION';
export const SET_SHOW_SIDE_BAR = 'SET_SHOW_SIDE_BAR';
