const apiVersion = '/api/v1';

// User endpoints
export const userAuth = apiVersion.concat('/userauth');
export const userLoginUrl = apiVersion.concat('/userauth/login');
export const registerUserUrl = apiVersion.concat('/userauth/register');
export const userProfileUrl = apiVersion.concat('/user/profile');
export const updateUserProfileUrl = apiVersion.concat('/user/update-profile');
export const transactionStatusUrl = apiVersion.concat('/user/transaction-status');
export const addToFavoritesUrl = apiVersion.concat('/user/favourite-add');
export const removeFromFavoritesUrl = apiVersion.concat('/user/favourite-remove');
export const getFavoriteProductsUrl = apiVersion.concat('/user/favourites');
export const userTransactionsUrl = apiVersion.concat('/user/transactions');
export const userConfirmOrderUrl = apiVersion.concat('/user/transaction-delivery-confirmation');
export const userTransactionsHighlight = apiVersion.concat('/user/highlights');
export const userCredential = apiVersion.concat('/credentials');

// Merchant endpoints
export const merchantAuth = apiVersion.concat('/merchantauth');
export const productCategories = apiVersion.concat('/categories');
export const singleProductUpload = apiVersion.concat('/merchant/product-store');
export const profileUrl = apiVersion.concat('/merchant/profile');
export const updateMerchantProfileUrl = apiVersion.concat('/merchant/update-profile');
export const productsUrl = apiVersion.concat('/merchant/products');
export const transactionsUrl = apiVersion.concat('/merchant/transactions');
export const destroyProductUrl = apiVersion.concat('/merchant/product-destroy');
export const updateProductUrl = apiVersion.concat('/merchant/product-update');
export const updateOrderStatusUrl = apiVersion.concat('/merchant/transaction-sent-confirmation');
export const marchantTransactionsHighlight = apiVersion.concat('/merchant/highlights');
export const marchantPayoutHistory = apiVersion.concat('/merchant/transfers');
export const marchantPayout = apiVersion.concat('/merchant/transfer');
export const marchantPayoutValidation = apiVersion.concat('/merchant/transfer-name-validation');

// Shop-front endpoints
export const cartDetailsUrl = apiVersion.concat('/cart-details');
export const addToCartUrl = apiVersion.concat('/cart-add');
export const emptyCartUrl = apiVersion.concat('/cart-empty');
export const updateCartItemUrl = apiVersion.concat('/cart-update');
export const removeCartItemUrl = apiVersion.concat('/cart-delete');
export const merchantsListUrl = apiVersion.concat('/merchants');
export const merchantViewUrl = apiVersion.concat('/merchant-view');
export const listProductsByMerchants = apiVersion.concat('/products');
export const productDetailsUrl = apiVersion.concat('/product-view');
export const executePaymentUrl = apiVersion.concat('/user/cart-execute-payment');
export const shippingUrl = apiVersion.concat('/merchants/shipping-fees');
