import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CircularProgress, CssBaseline, ThemeProvider } from '@mui/material';
import { CookiesProvider } from 'react-cookie';
import { Suspense } from 'react';
import { Box } from '@mui/system';
import { SnackbarProvider } from 'notistack';
import combineTheme from '../theme';
import QuickLinks from '../components/quick-links/quick-links';
import { useSelector } from 'react-redux';
import { IStore } from '../interface/store-setup/store-interface';
import { lazy, FC } from 'react';

const StoreSetup = lazy(() => import('./store-setup'));
const StoreWrapper = lazy(() => import('../pages/Store/Store-wrapper'));

import './App.css';

const App: FC = () => {
  const mode = useSelector((state: IStore) => state.themeSlice.palette?.mode);
  return (
    <CookiesProvider>
      <SnackbarProvider maxSnack={3}>
        <ThemeProvider theme={combineTheme({ palette: { mode } })}>
          <BrowserRouter>
            <CssBaseline />
            <Suspense fallback={<FallbackUI />}>
              <Routes>
                <Route path="/*" element={<StoreSetup />} />
                <Route path="/:store_name/store/*" element={<StoreWrapper />} />
              </Routes>
            </Suspense>
            <QuickLinks />
          </BrowserRouter>
        </ThemeProvider>
      </SnackbarProvider>
    </CookiesProvider>
  );
};

const FallbackUI = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      height="100vh"
      width="100vw"
    >
      <CircularProgress size="10rem" />
    </Box>
  );
};

export default App;
