import { createTheme, ThemeOptions } from '@mui/material/styles';
import { getThemeFromStorage } from '../utils/theme';
import { purple } from '@mui/material/colors';

const defaultTheme: ThemeOptions = {
  spacing: (size: number) => `${10 * size}px`,
  palette: {
    primary: {
      main: purple[500],
    },
    secondary: {
      main: '#2adf20',
      light: '#2adf20',
      dark: '#2adf20',
    },
  },
  zIndex: {
    drawer: 10,
  },
};

const combineTheme = (options: ThemeOptions) =>
  Object.keys(getThemeFromStorage()).length > 0
    ? createTheme(getThemeFromStorage())
    : createTheme({ ...defaultTheme, ...options });

export default combineTheme;
