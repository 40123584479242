import {
  CartData,
  CartDataResponse,
  CartEmptyResponse,
  CartUpdateData,
  CartUpdateResponse,
  PaymentResponse,
  TransactionStatusResponse,
  updateProfileData,
} from '../../interface/api-response/cart';
import {
  addToCartUrl,
  cartDetailsUrl,
  emptyCartUrl,
  executePaymentUrl,
  removeCartItemUrl,
  transactionStatusUrl,
  updateCartItemUrl,
  updateUserProfileUrl,
} from '../../utils/endpoints';
import { checkAndHandleError } from '../http-error-handlers';
import http from '../http-service';

export const fetchCartData = async (): Promise<undefined | CartData> => {
  try {
    const res = await http.publicGet<CartDataResponse>(cartDetailsUrl);
    if (!res) throw new Error('An error has occured');
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res.data;
  } catch (error: any) {
    const message = error.message || 'An internal error has occured';
    throw new Error(message);
  }
};

export const clearCartService = async () => {
  try {
    const res = await http.publicPost<CartEmptyResponse>(emptyCartUrl, {});
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res?.message;
  } catch (error: any) {
    const message = error.message || 'An internal error has occured';
    throw new Error(message);
  }
};

export const updateCartItemService = async (body: CartUpdateData) => {
  try {
    const res = await http.publicPost<CartUpdateResponse>(updateCartItemUrl, body);
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res?.message;
  } catch (error: any) {
    const message = error.message || 'An internal error has occured';
    throw new Error(message);
  }
};

export const removeCartItemService = async (id: string) => {
  try {
    const res = await http.publicPost<CartUpdateResponse>(removeCartItemUrl, { id });
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res?.message;
  } catch (error: any) {
    const message = error.message || 'An internal error has occured';
    throw new Error(message);
  }
};
export const addToCartService = async (id: number | string) => {
  try {
    const res = await http.publicPost(addToCartUrl, { product_id: id });
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res;
  } catch (error: any) {
    const message = error.message || 'An internal error has occured';
    throw new Error(message);
  }
};

export const executePaymentService = async (msisdn: string) => {
  try {
    const res = await http.privateUserPost<PaymentResponse>(executePaymentUrl, { msisdn });
    const error = checkAndHandleError(res);
    if (!res) throw new Error('An error occured while processing your request');
    if (error) throw error;
    return res;
  } catch (error: any) {
    const message = error.message || 'An internal error has occured';
    throw new Error(message);
  }
};

export const checkTransactionStatusService = async (transref: string) => {
  try {
    const res = await http.privateUserPost<TransactionStatusResponse>(transactionStatusUrl, {
      transref,
    });
    if (!res) throw new Error('An error occured while processing your request');
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res;
  } catch (error: any) {
    const message = error.message || 'An internal error has occured';
    throw new Error(message);
  }
};

export const updateProfileService = async (updates: updateProfileData) => {
  try {
    const res = await http.privateUserPost(updateUserProfileUrl, updates);
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res;
  } catch (error: any) {
    const message = error.message || 'An internal error has occured';
    throw new Error(message);
  }
};
