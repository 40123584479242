import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers/index';

const middleware = [thunk];

const testDevTestRedux =
  process.env.NODE_ENV === 'development' &&
  //@ts-expect-error-Added redux dev tools - no type found
  window.__REDUX_DEVTOOLS_EXTENSION__;

const store = testDevTestRedux
  ? createStore(
      rootReducer,
      compose(
        applyMiddleware(...middleware),
        //@ts-expect-error-Added redux dev tools - no type found
        window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    )
  : createStore(rootReducer, compose(applyMiddleware(...middleware)));

export type StoreInterface = ReturnType<typeof store.getState>;
export type GetState = typeof store.getState;
export type DispatchInterface = typeof store.dispatch;

export default store;
