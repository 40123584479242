import React from 'react';
import DevErrorPage from './dev-error-page';
import ProdErrorPage from './prod-error-page';

type IState = { hasError: boolean; error: string; errorInfo: any };
type IProps = any;

class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = { ...this.state, hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    console.log('Error:::', error);
    const errorState = { hasError: true, error };
    return errorState;
  }

  componentDidCatch(error: any, info: any) {
    console.log('Error:::', error);
    console.log('Info:::', info);
    // log errors regading component failures
    // TODO update log service to properly save log errors(can be async)
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      // fallback UI
      return (
        <>
          {process.env.NODE_ENV === 'development' && <DevErrorPage />}
          {process.env.NODE_ENV === 'production' && <ProdErrorPage />}
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
