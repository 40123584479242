import {
  ProductData,
  Transaction,
  TransactionsData,
  UpdateProfile,
  ShippingData,
} from '../../interface/store-setup/merchant-interfaces';
import { Action, ActionCreator } from '../../interface/store-setup/store-interface';
import {
  destroyProduct,
  editProductService,
  fetchProductCategories,
  fetchProducts,
  fetchMerchantProfile,
  fetchTransactions,
  updateMerchantProfileService,
  fetchShippingDetails,
} from '../../services/merchant/merchant-store-services';
import {
  SET_FOCUS_TRANSACTION,
  SET_IS_FETCHING,
  SET_PRODUCTS,
  SET_PRODUCT_CATEGORIES,
  SET_PROFILE,
  SET_TRANSACTIONS,
  UPDATE_PRODUCT,
  SET_SHIPPING,
} from '../action-types';

export interface ProductCategoryItem {
  id: string;
  name: string;
  slug: string;
  product_counts: string;
}
const ProductCategories: ProductCategoryItem[] = [];

export interface UpdateProduct {
  name: string;
  description: string;
  amount: number;
  category_id: number;
  stock: number;
  active: number;
  id: number;
  slashed_amount: number;
}
export type ProductIndex = 'name' | 'description' | 'amount' | 'category_id' | 'stock' | 'active';
export interface MerchantProfile {
  id?: string;
  name?: string;
  full_name?: string;
  email?: string;
  msisdn?: string;
  address?: string;
  logo?: string;
  main_balance?: number;
  draft_balance?: number;
}

const profile: MerchantProfile = {};
const products: ProductData = {};
const transactions: TransactionsData = {};
const focusTransaction: { data?: Transaction } = {};
const shipping: ShippingData | any = {};

const initialState = {
  ProductCategories,
  isFetching: false,
  profile,
  products,
  transactions,
  focusTransaction,
  shipping,
};

export type IMerchantSlice = typeof initialState;

export const setIsfetching = (isFetching: boolean) => ({
  type: SET_IS_FETCHING,
  payload: isFetching,
});

export const setFocusTransaction: ActionCreator<Transaction> =
  (payload, notify = () => null, cleanup = () => null) =>
  (dispatch) => {
    dispatch({ type: SET_FOCUS_TRANSACTION, payload });
    cleanup({ success: true });
  };

export const editProduct: ActionCreator<UpdateProduct> =
  (updates, cleanup = () => null) =>
  async (dispatch, getState) => {
    if (!updates) return;
    const product = {
      ...getState().merchantSlice.products.records?.filter(
        (product) => product.id === updates.id
      )[0],
    };
    if (!product) return;
    try {
      dispatch(setIsfetching(true));
      const formData = new FormData();
      for (let [key, value] of Object.entries(updates)) {
        if (key === 'id') key = 'product_id';
        value = String(value);
        formData.set(key, value);
      }
      const res = await editProductService(formData);
      if (!res) throw new Error('An error occured while processing your request');
      cleanup('Successfully Updated Product', 'success');
    } catch (error: any) {
      cleanup(error.message || 'An error occured', 'error');
    } finally {
      const newProduct = { ...product, ...updates };
      dispatch({ type: UPDATE_PRODUCT, payload: newProduct });
      dispatch(setIsfetching(false));
    }
  };

export const deleteProduct: ActionCreator<string> =
  (productId, cleanup = () => null) =>
  async (dispatch, getState) => {
    if (!productId) return;
    try {
      dispatch(setIsfetching(true));
      const res = await destroyProduct(productId);
      if (!res) throw new Error('An error occured while processing your request');
      const products = getState().merchantSlice.products;
      dispatch({
        type: SET_PRODUCTS,
        payload: {
          ...products,
          records: products.records?.filter((product) => product.id !== Number(productId)),
        },
      });
      cleanup('Successfully deleted Product', 'success');
    } catch (error: any) {
      cleanup(error.message || 'An error occured', 'error');
    } finally {
      dispatch(setIsfetching(false));
    }
  };

export const getTransactions: ActionCreator =
  (_, notify = () => null, cleanup = () => null) =>
  async (dispatch, getState) => {
    if (getState().merchantSlice.transactions.count) return;
    try {
      dispatch(setIsfetching(true));
      const transactions = await fetchTransactions();
      if (!transactions) throw new Error('An error occured while processing your request');
      dispatch({ type: SET_TRANSACTIONS, payload: transactions.data });
      notify('Successfully fetched Transactions', 'success');
    } catch (error: any) {
      notify(error.message || 'An error occured', 'error');
    } finally {
      dispatch(setIsfetching(false));
      cleanup({ default: true });
    }
  };

export const updateTransactions: ActionCreator<number | undefined> =
  (focusTransactionID, notify = () => null, cleanup = () => null) =>
  async (dispatch, getState) => {
    if (getState().merchantSlice.transactions.count) return;
    try {
      dispatch(setIsfetching(true));
      const transactions = await fetchTransactions();
      if (!transactions) throw new Error('An error occured while processing your request');
      dispatch({ type: SET_TRANSACTIONS, payload: transactions.data });
      if (focusTransactionID) {
        const focusTransaction = transactions.data.records?.find(
          (transaction) => transaction.id == focusTransactionID
        );
        dispatch({ type: SET_FOCUS_TRANSACTION, payload: focusTransaction });
      }
      notify('Successfully fetched Transactions', 'success');
    } catch (error: any) {
      notify(error.message || 'An error occured', 'error');
    } finally {
      dispatch(setIsfetching(false));
      cleanup({ default: true });
    }
  };

export const getProducts: ActionCreator<boolean> =
  (shouldRefresh, cleanup = () => null) =>
  async (dispatch, getState) => {
    const productCount = getState().merchantSlice.products.count;
    // if the data has already been fetched
    if (productCount && !shouldRefresh) return;
    try {
      dispatch(setIsfetching(true));
      const products = await fetchProducts();
      if (!products) throw new Error('An error occured while processing your request');
      dispatch({ type: SET_PRODUCTS, payload: products.data });
      cleanup('Successfully fetched products', 'success');
    } catch (error: any) {
      cleanup(error.message || 'An error occured', 'error');
    } finally {
      dispatch(setIsfetching(false));
    }
  };

export const getShippingDetails: ActionCreator<boolean> =
  (shouldRefresh, cleanup = () => null) =>
  async (dispatch, getState) => {
    try {
      dispatch(setIsfetching(true));
      const shipping = await fetchShippingDetails();
      if (!shipping) throw new Error('An error occured while processing your request');
      dispatch({ type: SET_SHIPPING, payload: shipping.data });
      cleanup('Successfully fetched shipping detals', 'success');
    } catch (error: any) {
      cleanup(error.message || 'An error occured', 'error');
    } finally {
      dispatch(setIsfetching(false));
    }
  };
export const getProfile: ActionCreator =
  (_, cleanup = () => null) =>
  async (dispatch, getState) => {
    // check if profile already exists in store
    if (getState().merchantSlice.profile.id) return;

    try {
      dispatch(setIsfetching(true));
      const profile = await fetchMerchantProfile();
      if (!profile) throw new Error('An error occured while processing your request');
      dispatch({ type: SET_PROFILE, payload: profile.data[0] });
      // cleanup('Successfully fetched profile data', 'success');
    } catch (error: any) {
      cleanup(error.message || 'An error occured', 'error');
    } finally {
      dispatch(setIsfetching(false));
    }
  };

export const updateMerchantProfile: ActionCreator<UpdateProfile> =
  (data, notify = () => null, cleanup = () => null) =>
  async (dispatch) => {
    if (!data) return;

    try {
      await updateMerchantProfileService(data);
      // fetch the updated profile
      const profile = await fetchMerchantProfile();
      if (!profile) throw new Error('An error occured while processing your request');
      dispatch({ type: SET_PROFILE, payload: profile.data[0] });
    } catch (error: any) {
      notify(error.message || 'An error occured', 'error');
    } finally {
      cleanup();
    }
  };

export const setCategories = (body: ProductCategoryItem[]) => ({
  type: SET_PRODUCT_CATEGORIES,
  payload: body,
});

export const getProductCategories: ActionCreator<boolean> =
  (_, notify = () => null) =>
  async (dispatch, getState) => {
    try {
      if (getState().merchantSlice.ProductCategories.length === 0) {
        dispatch(setIsfetching(true));
        const categories = await fetchProductCategories();
        dispatch(setCategories(categories));
      }
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
    } finally {
      setTimeout(() => {
        dispatch(setIsfetching(false));
      }, 3000);
    }
  };

export const merchantSlice = (
  state: IMerchantSlice = initialState,
  action: Action
): IMerchantSlice => {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_FETCHING:
      return { ...state, isFetching: payload };
    case SET_PRODUCT_CATEGORIES:
      return { ...state, ProductCategories: payload };
    case SET_PROFILE:
      return { ...state, profile: payload };
    case SET_PRODUCTS:
      return { ...state, products: payload };
    case SET_SHIPPING:
      return { ...state, shipping: payload };
    case SET_TRANSACTIONS:
      return { ...state, transactions: payload };
    case SET_FOCUS_TRANSACTION:
      return { ...state, focusTransaction: { data: payload } };
    case UPDATE_PRODUCT:
      return updateProduct(state, action);
    default:
      return state;
  }
};

function updateProduct(state: IMerchantSlice, action: Action) {
  const { payload } = action;
  const newRecords = state.products.records?.map((product) =>
    product.id === payload.id ? payload : product
  );
  return { ...state, products: { ...state.products, records: newRecords } };
}
