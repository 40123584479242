import {
  ProductListFilters,
  FocusProduct,
  Merchant,
  StoreProduct,
} from '../../interface/api-response/store-front';
import { ShippingData } from '../../interface/api-response/deliverymanagement';
import { fetchShippingDetails } from '../../services/shopfront/delivery-management';
import {
  Action,
  ActionCreator,
  FavoriteProduct,
  FetchProductsCleanUpData,
} from '../../interface/store-setup/store-interface';
import { StoreFrontUser } from '../../interface/store-setup/user-slice-interface';
import { fetchProductCategories } from '../../services/merchant/merchant-store-services';
import {
  addToFavoritesService,
  FetchFavoriteProducts,
  fetchMerchant,
  fetchProductDetails,
  fetchProductsByMerchant,
  loginStoreUserService,
  registerStoreUserService,
  removeFromFavoritesService,
} from '../../services/shopfront/store-setup';
import { removeUndefinedValues } from '../../utils/helpers/help';
import { storeUserStorageKey } from '../../utils/helpers/local-storage-keys';
import { isUserLoggedIn } from '../../utils/helpers/login-helper';
import encryptStorage from '../../utils/storage';
import {
  ADD_TO_PRODUCTS,
  LOGIN_STORE_USER,
  REGISTER_STORE_USER,
  SET_FOCUS_PRODUCT,
  SET_IS_FETCHING,
  SET_IS_FETCHING_DATA,
  SET_MERCHANT,
  SET_PRODUCTS,
  UPDATE_FOCUS_PRODUCT,
  LOGIN_USER_WITH_STORAGE_DATA,
  LOG_OUT_USER,
  SET_FAVORITES,
  UPDATE_FILTERS,
  CLEAR_PRODUCTS,
  CLEAR_FILTERS,
  SET_SHOW_SIDE_BAR,
  SET_SHIPPING,
} from '../action-types';

import { updateFrontEndCart } from './cart';

export interface ProductCategories {
  id: string;
  name: string;
  slug: string;
  product_counts: string;
}
interface StoreMerchant {
  data?: Merchant;
}
interface StoreFavorites {
  count: number;
  records: FavoriteProduct[];
}

const categories: ProductCategories[] = [];
const merchant: StoreMerchant = {};
const products: { data: { [pageNo: string]: StoreProduct[] } } = { data: { '1': [] } };
const focusProduct: FocusProduct = { merchant_other_products: [] };
const initialUserData = { access_token: '', token_type: '' };
const favorites: StoreFavorites = { count: 0, records: [] };
const appliedFilters: ProductListFilters = {
  max_amount: '',
  min_amount: '',
  category_id: '',
  query: '',
  page: 1,
};
const shippingfee: ShippingData | any = {};

const initialState = {
  message: '',
  data: initialUserData,
  categories,
  activeCategory: '',
  isFetchingProduct: false,
  isFetchingData: false,
  merchant,
  products,
  focusProduct,
  favorites,
  appliedFilters,
  showSidebar: false,
  shippingfee,
};

export type ShopFrontSlice = typeof initialState;

export const setIsfetching = (isFetching: boolean) => ({
  type: SET_IS_FETCHING,
  payload: isFetching,
});

export const setShowSideBar = (state: boolean) => ({
  type: SET_SHOW_SIDE_BAR,
  payload: state,
});

const setIsfetchingData = (state: boolean) => ({ type: SET_IS_FETCHING_DATA, payload: state });

export const setProductCategories = (body: ProductCategories[]) => ({
  type: SET_PRODUCTS,
  payload: body,
});

export const setShippingFee = (body: ShippingData[]) => ({
  type: SET_SHIPPING,
  payload: body,
});

const setCurrentMerchant = (body: Merchant) => ({
  type: SET_MERCHANT,
  payload: body,
});

const addProducts = (pageNo: string, products: StoreProduct[]) => ({
  type: ADD_TO_PRODUCTS,
  payload: { pageNo, products },
});

export const setFocusProduct = (product: StoreProduct) => ({
  type: SET_FOCUS_PRODUCT,
  payload: product,
});

export const loginStoreUserWithStorageData = (payload: any) => ({
  type: LOGIN_USER_WITH_STORAGE_DATA,
  payload,
});

export const setStoreFavorites = (payload: StoreFavorites) => ({
  type: SET_FAVORITES,
  payload,
});

export const loginStoreUser: ActionCreator<StoreFrontUser> =
  (user, cleanup = () => null) =>
  async (dispatch, getState) => {
    if (!user) return;
    try {
      const res = await loginStoreUserService(user);
      dispatch({ type: LOGIN_STORE_USER, payload: res });
      cleanup('login Successfull', 'success');
      console.log(res);
      // fetch favorite products

      const currentMerchant = getState().shopFrontSlice.merchant.data;
      if (!currentMerchant) return;
      const favorites = await FetchFavoriteProducts();
      // filter favorites by merchant
      favorites.records = favorites.records.filter(
        (item) => item.merchant_id === currentMerchant.id
      );
      dispatch(setStoreFavorites(favorites));
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      cleanup(message + ' ' + '...Please make sure that your email has been verified', 'error');
    }
  };

export const registerStoreUser: ActionCreator<StoreFrontUser> =
  (user, cleanup = () => null) =>
  async () => {
    if (!user) return;
    try {
      await registerStoreUserService(user);
      cleanup('Registration successfull', 'success');
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      cleanup(message, 'error');
    }
  };

export const getFocusProduct: ActionCreator<string> =
  (id, cleanup = () => null) =>
  async (dispatch) => {
    if (!id) return;
    dispatch(setIsfetchingData(true));
    try {
      const focusProduct = await fetchProductDetails(id);
      if (!focusProduct) throw new Error('An error occured while fetching the data');
      dispatch({ type: UPDATE_FOCUS_PRODUCT, payload: focusProduct });
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      cleanup(message, 'error');
    } finally {
      dispatch(setIsfetchingData(false));
    }
  };

export const getStoreMerchant: ActionCreator<string> =
  (storeName, notify = () => null, cleanup = () => null) =>
  async (dispatch) => {
    if (!storeName) return;
    try {
      const currentMerchant = await fetchMerchant(storeName);
      if (!currentMerchant) throw new Error('An error occured while updating merchants');
      dispatch(setCurrentMerchant(currentMerchant));
      cleanup({ success: true });
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
      cleanup({ error: true });
    } finally {
      cleanup();
    }
  };

export const fetchPagedStoreProuducts: ActionCreator<
  ProductListFilters,
  FetchProductsCleanUpData
> =
  (data, notify = () => null, cleanup = () => null) =>
  async (dispatch, getState) => {
    const page = data?.page;
    // update name with store data
    const merchant = getState().shopFrontSlice.merchant.data;
    const storeName = merchant?.username;
    if (!storeName || !page) return;

    try {
      dispatch(setIsfetchingData(true));

      // first Identify Merchant
      const currentMerchant = await fetchMerchant(storeName);
      if (!currentMerchant) throw new Error('An error occured while updating merchants');
      dispatch(setCurrentMerchant(currentMerchant));

      // Check if product were fetched on a previous render.
      const productForPage = getState().shopFrontSlice.products.data[page];
      const appliedFilters = removeUndefinedValues(getState().shopFrontSlice.appliedFilters);

      if (!productForPage || !productForPage.length) {
        // then retrieve products by Merchants
        const records = await fetchProductsByMerchant({
          merchant_id: String(currentMerchant.id),
          ...appliedFilters,
        });
        if (!records) throw new Error('An error occured while updating merchants');
        const { data, last_page } = records;
        // clean up
        cleanup({ success: true, data: { productList: data, noOfPages: Number(last_page) } });
        // update cart
        dispatch(addProducts(String(page), data));
      } else {
        cleanup({ success: true, data: { productList: productForPage } });
      }
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
      cleanup({ error: true });
    } finally {
      cleanup();
      dispatch(setIsfetchingData(false));
    }
  };

export const getProductsCategories: ActionCreator<undefined> =
  (_, notify = () => null) =>
  async (dispatch) => {
    dispatch(setIsfetching(true));
    try {
      const categories = await fetchProductCategories();
      dispatch(setProductCategories(categories));
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
    } finally {
      dispatch(setIsfetching(false));
    }
  };

export const addToFavorites: ActionCreator<number> =
  (productId, notify = () => null) =>
  async (dispatch, getState) => {
    if (!productId) return;
    if (!isUserLoggedIn('store-user'))
      return notify('Please login to add products to your favorites list', 'warning');

    // get the merchant profile
    const currentMerchant = getState().shopFrontSlice.merchant.data;
    if (!currentMerchant) return;
    try {
      // add item
      await addToFavoritesService(productId);
      notify('Successfully added to favorites', 'success');

      // update store favorites
      const favorites = await FetchFavoriteProducts();

      // filter favorites by merchant
      favorites.records = favorites.records.filter(
        (item) => item.merchant_id === currentMerchant.id
      );

      dispatch(setStoreFavorites(favorites));
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
    } finally {
    }
  };

export const removeFromFavorites: ActionCreator<number> =
  (favoriteId, notify = () => null, cleanup = () => null) =>
  async (dispatch, getState) => {
    if (!favoriteId) return;
    try {
      // add item
      await removeFromFavoritesService(favoriteId);
      notify('Done', 'success');

      // update store favorites
      const currentFavorites = getState().shopFrontSlice.favorites.records;
      const newFavorites = currentFavorites.filter((item) => item.favourite_id !== favoriteId);
      dispatch(setStoreFavorites({ count: newFavorites.length, records: newFavorites }));
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
    } finally {
      cleanup();
    }
  };

export const getFavoriteProducts: ActionCreator =
  (_, notify = () => null) =>
  async (dispatch, getState) => {
    // check if user is logged in
    if (!isUserLoggedIn('store-user'))
      return notify('Please login to view your favorites list', 'warning');

    // get the merchant profile
    const currentMerchant = getState().shopFrontSlice.merchant.data;
    if (!currentMerchant) return;

    try {
      const favorites = await FetchFavoriteProducts();

      // filter favorites by merchant
      favorites.records = favorites.records.filter(
        (item) => item.merchant_id === currentMerchant.id
      );
      dispatch(setStoreFavorites(favorites));
      notify('Successfully fetched favorites', 'success');
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
    } finally {
    }
  };

export const getShippingData: ActionCreator<any> =
  (_, notify = () => null) =>
  async (dispatch) => {
    dispatch(setIsfetching(true));
    try {
      const shippingfee = await fetchShippingDetails();
      dispatch(setShippingFee(shippingfee));
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
    } finally {
      dispatch(setIsfetching(false));
    }
  };

const clearuser = () => ({
  type: LOG_OUT_USER,
  payload: initialUserData,
});

export const updateFilters = (payload: ProductListFilters) => ({
  type: UPDATE_FILTERS,
  payload,
});

export const clearFilters = () => ({
  type: CLEAR_FILTERS,
  payload: appliedFilters,
});

export const clearProductsList = () => ({
  type: CLEAR_PRODUCTS,
  payload: {},
});

export const logoutStoreUser: ActionCreator = () => (dispatch) => {
  encryptStorage.setItem(storeUserStorageKey, undefined);
  dispatch(clearuser());
};

export const updateFiltersAndFetchProducts: ActionCreator<ProductListFilters> =
  (filters) => (dispatch) => {
    if (!filters) return;
    dispatch(clearProductsList());
    dispatch(updateFilters(filters));
  };

export const shopFrontSlice = (
  state: ShopFrontSlice = initialState,
  action: Action
): ShopFrontSlice => {
  const { type, payload } = action;
  switch (type) {
    case SET_IS_FETCHING:
      return { ...state, isFetchingProduct: payload };
    case SET_IS_FETCHING_DATA:
      return { ...state, isFetchingData: payload };
    case SET_PRODUCTS:
      return { ...state, categories: payload };
    case SET_MERCHANT:
      return { ...state, merchant: { data: payload } };
    case SET_FAVORITES:
      return { ...state, favorites: payload };
    case ADD_TO_PRODUCTS:
      return {
        ...state,
        products: { data: { ...state.products.data, [payload.pageNo]: payload.products } },
      };
    case CLEAR_PRODUCTS:
      return { ...state, products: { data: {} } };
    case SET_FOCUS_PRODUCT:
      return { ...state, focusProduct: { product: payload } };
    case UPDATE_FOCUS_PRODUCT:
      return { ...state, focusProduct: payload };
    case LOGIN_STORE_USER:
      return { ...state, data: payload };
    case REGISTER_STORE_USER:
      return { ...state, data: payload };
    case LOGIN_USER_WITH_STORAGE_DATA:
      return { ...state, data: payload.data };
    case LOG_OUT_USER:
      return { ...state, data: payload };
    case UPDATE_FILTERS:
      return { ...state, appliedFilters: { ...state.appliedFilters, ...payload } };
    case SET_SHOW_SIDE_BAR:
      return { ...state, showSidebar: payload };
    case CLEAR_FILTERS:
      return { ...state, appliedFilters: payload };
    case SET_SHIPPING:
      return { ...state, shippingfee: payload };
    default:
      return state;
  }
};
