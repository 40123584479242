import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { RequestTypes } from '../interface/store-setup/store-interface';
import { logFetchErrors } from '../utils/errors-logs';
import { merchantStorageKey, storeUserStorageKey } from '../utils/helpers/local-storage-keys';
import encryptStorage from '../utils/storage';

let baseURL = '';
// set the base url depending on three environment variables
export const checkEnvironment = (): string => {
  if (process.env.REACT_APP_IGNORE_NODE_ENV === 'true') return 'development';
  return process.env.NODE_ENV || '';
};

switch (checkEnvironment()) {
  case 'development':
    baseURL = process.env.REACT_APP_BASE_API_DEV || '';
    break;
  case 'production':
    baseURL = process.env.REACT_APP_BASE_API_PROD || '';
    break;
  default:
    baseURL = '';
}

const axiosInstance: AxiosInstance = axios.create({
  baseURL,
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.post['Accept'] = 'application/json';
axiosInstance.defaults.timeout = 180000;
axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.request.use((request) => {
  // intercept all requests
  return request;
});

axiosInstance.interceptors.response.use((response) => {
  // intercept all response
  return response;
});

const setRequestAuthorization = (requestType: RequestTypes) => {
  let token;
  // if requestType is not defined use public token
  if (!requestType) token = process.env.REACT_APP_PUBLIC_API_TOKEN;
  else if (requestType === 'merchant')
    token = encryptStorage.getItem(merchantStorageKey)?.data?.access_token;
  else token = encryptStorage.getItem(storeUserStorageKey)?.data?.access_token;

  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

async function get<T>(
  url: string,
  requestType?: RequestTypes,
  config?: AxiosRequestConfig<any>
): Promise<T | undefined> {
  setRequestAuthorization(requestType);
  try {
    const response = await axiosInstance.get(url, config);
    return response.data;
  } catch (error: any) {
    const data = handleFetchErrors(error);
    if (data) return data;
    throw new Error('Something went wrong while processing your requests');
  }
}

async function post<T>(
  url: string,
  body: any,
  requestType?: RequestTypes,
  config?: AxiosRequestConfig<any>
): Promise<T | undefined> {
  setRequestAuthorization(requestType);
  try {
    const response = await axiosInstance.post(url, body, config);
    return response.data;
  } catch (error: any) {
    const data = handleFetchErrors(error);
    if (data) return data;
    throw new Error('Something went wrong while processing your requests');
  }
}

const handleFetchErrors = (error: any) => {
  logFetchErrors(error);
  const err: AxiosError = error;
  if (err.response && err.response.data) return err.response.data;
};

async function publicGet<T>(url: string, config?: AxiosRequestConfig<any>) {
  return get<T>(url, undefined, config);
}
async function publicPost<T>(url: string, body: any, config?: AxiosRequestConfig<any>) {
  return post<T>(url, body, undefined, config);
}
async function privateMerchantGet<T>(url: string, config?: AxiosRequestConfig<any>) {
  return get<T>(url, 'merchant', config);
}
async function privateMerchantPost<T>(url: string, body: any, config?: AxiosRequestConfig<any>) {
  return post<T>(url, body, 'merchant', config);
}
async function privateUserGet<T>(url: string, config?: AxiosRequestConfig<any>) {
  return get<T>(url, 'user', config);
}
async function privateUserPost<T>(url: string, body: any, config?: AxiosRequestConfig<any>) {
  return post<T>(url, body, 'user', config);
}
const http = {
  get,
  post,
  publicGet,
  privateMerchantGet,
  privateMerchantPost,
  publicPost,
  privateUserPost,
  privateUserGet,
};
export default http;
