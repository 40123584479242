// import { EncryptStorage } from 'encrypt-storage';

// const secretKey = process.env.REACT_APP_SECRET_KEY || 'secret-key';
// console.log('secret key:::', secretKey);

// const encryptLocalStorage = new EncryptStorage(secretKey, {
//   storageType: 'localStorage',
//   encAlgorithm: 'AES',
// });

// const encryptSessionStorage = new EncryptStorage(secretKey, {
//   storageType: 'sessionStorage',
// });

const encryptStorage = {
  getItem(key: string) {
    try {
      const item = localStorage.getItem(key);
      if (!item) return;
      return JSON.parse(item);
    } catch (error) {
      return;
    }
  },
  setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  clear() {
    localStorage.clear();
  },
};
export default encryptStorage;
