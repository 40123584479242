import { Box, Typography, Button } from '@mui/material';
import E404 from '../../assets/404/error404.jpg';

function ProdErrorPage() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        height: '100vh',
        textAlign: 'center',
        padding: {
          xs: '0.5rem 0',
          sm: '0.5rem 5rem',
          md: '0.5rem 6rem',
          lg: '0.5rem 12rem',
        },
      }}
    >
      {/* <Typography
        sx={{
          textShadow: '10px 25px 10px gray',
          fontSize: {
            xs: 50,
            sm: 50,
            md: 200,
            lg: 200,
          },
          fontWeight: 'bolder',
        }}
      >
        \(^=^)/
      </Typography> */}
      <img src={E404} alt="Error Image" width={350} />
      <h2 style={{ fontSize: '35px', marginBottom: '5px' }}>Page Not Found</h2>

      <p style={{ fontWeight: '500' }}>
        Ooops, it seems something has gone wrong. Dont worry we have been notified of the error and
        will fix it in no time
      </p>

      <Button
        sx={{ margin: '0.5rem 1rem' }}
        color="error"
        variant="contained"
        onClick={() => window.location.assign('/')}
      >
        Go back Home page
      </Button>
    </Box>
  );
}

export default ProdErrorPage;
