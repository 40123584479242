import { ProductListFilters } from '../../interface/api-response/store-front';
import { checkEnvironment } from '../../services/http-service';

export const validEmailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);

export const formatNumber = (text: string) => {
  if (!text) return '';
  return isNaN(Number(text)) ? text : Number(text).toLocaleString();
};

export const stringToDate = (text: string) => {
  try {
    const date = new Date(text);
    const dateString = date.toLocaleString();
    return dateString;
  } catch (error) {
    return 'N/A';
  }
};

export const fetchImageSrc = async (
  imageUrl: string,
  controller: AbortController
): Promise<string> => {
  // add logic to prefetch image

  // const res = await axios({
  //   method: 'GET',
  //   url: `${imageUrl}?not-from-cache-please`,
  //   responseType: 'blob',
  //   signal: controller.signal,
  // });

  // console.log(res.data);
  // const url = URL.createObjectURL(res.data);

  return imageUrl;
};

export const getSettlementAppUrl = () => {
  switch (checkEnvironment()) {
    case 'development':
      return process.env.REACT_APP_SETTLEMENT_APP_DEV;
    case 'production':
      return process.env.REACT_APP_SETTLEMENT_APP_PROD;
    default:
      return '';
  }
};

export const removeUndefinedValues = (filters: ProductListFilters): ProductListFilters => {
  return filters;
};

export const getStoreUrl = (name: string | undefined) => {
  if (!name) return;
  let base;
  if (process.env.REACT_APP_IGNORE_NODE_ENV === 'true') base = process.env.REACT_APP_LINK_TEST;
  else {
    switch (checkEnvironment()) {
      case 'development':
        base = process.env.REACT_APP_LINK_DEV;
        break;
      case 'production':
        base = process.env.REACT_APP_LINK_PROD;
        break;
      default:
        base = '';
    }
  }
  const storeName = name.toLocaleLowerCase().trim().split(' ').join('-');
  return [base, storeName, 'store'].join('/');
};

export const computeDiscount = (slashed_amount: number, amount: number) => {
  try {
    const discount = slashed_amount - amount;
    const percent = Math.round((discount / amount) * 100);
    return percent;
  } catch (error) {
    return 0;
  }
};
