import { Dispatch } from 'redux';
import { CartData, CartUpdateData, updateProfileData } from '../../interface/api-response/cart';
import { FocusProduct } from '../../interface/api-response/store-front';
import { StoreUserProfile } from '../../interface/api-response/user-setup';
import { Action, ActionCreator } from '../../interface/store-setup/store-interface';
import * as services from '../../services/shopfront/cart-management';
import { fetchProductDetails, fetchStoreUserProfile } from '../../services/shopfront/store-setup';
import * as actions from '../action-types';

interface Cart {
  data?: CartData;
}
interface CartProductDetail {
  data?: { [productId: string]: FocusProduct };
}
interface UserProfile {
  data?: StoreUserProfile;
}

const profile: UserProfile = {};
const initialCartData: Cart = {};
const initialCartProductDetail: CartProductDetail = {};

const initialState = {
  isFetchingData: false,
  cartData: initialCartData,
  cartProductDetail: initialCartProductDetail,
  profile,
  completedPayment: false,
  paymentErrorStatus: false,
};

export type CartSlice = typeof initialState;

export const setIsfetching = (isFetching: boolean) => ({
  type: actions.SET_IS_FETCHING,
  payload: isFetching,
});

export const setPaymentStatus = (status: boolean) => ({
  type: actions.SET_PAYMENT_STATUS,
  payload: status,
});

// const setPaymentErrorStatus = (status: boolean) => ({
//   type: actions.SET_PAYMENT_ERROR_STATUS,
//   payload: status,
// });

const setStoreProfile = (profile: StoreUserProfile) => ({
  type: actions.SET_STORE_USER_PROFILE,
  payload: profile,
});

export const updateProfile: ActionCreator<updateProfileData> =
  (updates, notify = () => null, cleanup = () => null) =>
  async (dispatch) => {
    if (!updates) return;
    try {
      // update profile info
      await services.updateProfileService(updates);

      // fetch profile info
      const profile = await fetchStoreUserProfile();
      if (!profile)
        throw new Error(
          'An error occured while fetching your profile information please try again later'
        );
      dispatch(setStoreProfile(profile));

      notify('Product successfully update profile info', 'success');
      cleanup();
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
      cleanup();
    }
  };

export const addToCart: ActionCreator<string | number> =
  (id, notify = () => null, cleanup = () => null) =>
  async (dispatch) => {
    if (!id) return;
    cleanup({ start: true });
    try {
      // add to cart)
      await services.addToCartService(id);

      // update front end cart info
      const cartData = await services.fetchCartData();
      dispatch({ type: actions.SET_CART_DATA, payload: cartData });

      notify('Product successfully added to the shopping cart', 'success');
      cleanup({ success: true });
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
      cleanup({ error: true });
    } finally {
      cleanup({ default: true });
    }
  };

export const getCartData: ActionCreator =
  (_, notify = () => null) =>
  async (dispatch) => {
    dispatch(setIsfetching(true));
    try {
      const cartData = await services.fetchCartData();
      dispatch({ type: actions.SET_CART_DATA, payload: cartData });
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
    } finally {
      dispatch(setIsfetching(false));
    }
  };

export const clearCart: ActionCreator =
  (_, notify = () => null, cleanup = () => null) =>
  async (dispatch) => {
    dispatch(setIsfetching(true));
    try {
      // clear cart
      await services.clearCartService();
      dispatch({ type: actions.CLEAR_CART_DATA, payload: {} });
      notify('successfully cleared the cart', 'success');

      // update front end cart info
      const cartData = await services.fetchCartData();
      dispatch({ type: actions.SET_CART_DATA, payload: cartData });
      cleanup();
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
    } finally {
      dispatch(setIsfetching(false));
    }
  };

const incompleteProfileMessage =
  'Please make sure to include both your msisdn number and your address before completing your request';

export const initializePayment: ActionCreator =
  (_, notify = () => null, cleanup = () => null) =>
  async (dispatch, getState) => {
    // check if user is logged in
    if (!getState().shopFrontSlice.data.access_token) {
      //user is not logged in
      notify('Please Log In or sign up to complete Payment', 'warning');
      cleanup();
      return;
    }

    try {
      // get user profile
      const profile = await fetchStoreUserProfile();
      if (!profile)
        throw new Error(
          'An error occured while fetching your profile information please try again later'
        );
      dispatch(setStoreProfile(profile));
      cleanup();

      if (!profile.msisdn || !profile.address) notify(incompleteProfileMessage, 'warning');
      cleanup();
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
    } finally {
      dispatch(setIsfetching(false));
      cleanup();
    }
  };
export const getUserProfile: ActionCreator =
  (_, notify = () => null, cleanup = () => null) =>
  async (dispatch) => {
    try {
      // fetch profile info
      const profile = await fetchStoreUserProfile();
      if (!profile)
        throw new Error(
          'An error occured while fetching your profile information please try again later'
        );
      dispatch(setStoreProfile(profile));

      notify('successfully fetched profile info', 'success');
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
    } finally {
      cleanup({ default: true });
    }
  };
export const executePayment: ActionCreator<string> =
  (msisdn, notify = () => null, cleanup = () => null) =>
  async (dispatch) => {
    if (!msisdn) return;
    try {
      const res = await services.executePaymentService(msisdn);
      const transref = res.data.transref;
      if (!transref) throw new Error('An error occured while processing your request');

      let checkInterval: NodeJS.Timer | undefined = undefined;
      let isTransactionSuccessful = false;

      const checkTranactionStatus = () =>
        services.checkTransactionStatusService(transref).then((res) => {
          if (res.data.status === 'successful') {
            isTransactionSuccessful = true;
            clearInterval(checkInterval as NodeJS.Timeout);
            cleanup({ success: true });
          }
        });

      const fiveSeconds = 1000 * 5;
      const fiveMinutes = 1000 * 60 * 5;
      checkInterval = setInterval(checkTranactionStatus, fiveSeconds);

      setTimeout(() => {
        clearInterval(checkInterval as NodeJS.Timeout);
        notify('We had an issue verifying your transaction please try agian later', 'error');
        if (isTransactionSuccessful) cleanup({ success: true });
        else cleanup({ error: true });
      }, fiveMinutes);

      notify(
        'You may continue shoping, You can check the status of your transaction from your dashboard',
        'info'
      );
      cleanup();
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      if (message === `Operator Unknown .${msisdn}`) {
        notify('Mobile Money Number is invalid please try a valid number', 'error');
      } else {
        notify(message, 'error');
      }
    } finally {
      dispatch(setIsfetching(false));
    }
  };
export const getCartProductDetail: ActionCreator<number> =
  (id, cleanup = () => null) =>
  async (dispatch) => {
    try {
      const cartData = await fetchProductDetails(String(id));
      if (!cartData) throw new Error('An error occured while fetching product details');
      dispatch({ type: actions.SET_CART_PRODUCT_DETAIL, payload: cartData });
      cleanup('success', 'info');
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      cleanup(message, 'error');
    } finally {
    }
  };
export const updateCartItem: ActionCreator<CartUpdateData> =
  (updates, notify = () => null) =>
  async (dispatch) => {
    if (!updates) return;
    try {
      const res = await services.updateCartItemService(updates);
      if (!res) throw new Error('An error occured while fetching product details');
      // notify('update successfull', 'success');

      updateFrontEndCart(dispatch);
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
    } finally {
    }
  };

export const removeCartItem: ActionCreator<string> =
  (id, notify = () => null) =>
  async (dispatch) => {
    if (!id) return;
    try {
      const res = await services.removeCartItemService(id);
      if (!res) throw new Error('An error occured while fetching product details');
      notify('successfully deleted', 'success');

      updateFrontEndCart(dispatch);
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
    } finally {
    }
  };
export const cartSlice = (state: CartSlice = initialState, action: Action): CartSlice => {
  const { type, payload } = action;
  switch (type) {
    case actions.SET_IS_FETCHING:
      return { ...state, isFetchingData: payload };
    case actions.SET_CART_DATA:
      return { ...state, cartData: { data: payload } };
    case actions.SET_STORE_USER_PROFILE:
      return { ...state, profile: { data: payload } };
    case actions.SET_CART_PRODUCT_DETAIL:
      return {
        ...state,
        cartProductDetail: {
          data: { ...state.cartProductDetail.data, [payload.product.id]: payload },
        },
      };
    default:
      return state;
  }
};

export async function updateFrontEndCart(dispatch: Dispatch) {
  const cartData = await services.fetchCartData();
  dispatch({ type: actions.SET_CART_DATA, payload: cartData });
}
