import { RegisterUserResponse } from '../../interface/api-response/user-setup';
import { LoginCredentials } from '../../interface/components/login';
import { merchantAuth } from '../../utils/endpoints';
import { merchantStorageKey } from '../../utils/helpers/local-storage-keys';
import encryptStorage from '../../utils/storage';
import { checkAndHandleError } from '../http-error-handlers';
import http from '../http-service';

// const url = 'http://74.208.84.251:9004/api/v1/auth/signin';
export const loginUserService = async (
  body: LoginCredentials
): Promise<RegisterUserResponse | undefined> => {
  const { email, password } = body;
  const requestBody = {
    email,
    password,
  };
  try {
    const res = await http.post<RegisterUserResponse>(merchantAuth.concat('/login'), requestBody);
    const error = checkAndHandleError(res);
    if (error) throw error;
    encryptStorage.setItem(merchantStorageKey, res);
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
