import { ThemeOptions } from '@mui/material';
import { Action } from '../../interface/store-setup/store-interface';
import { CHANGE_MODE } from '../action-types';

const initialState: ThemeOptions = {
  palette: { mode: 'light' },
};

export type IThemeSlice = typeof initialState;
type ValidMode = 'light' | 'dark';

export const changeMode = (mode: ValidMode) => ({
  type: CHANGE_MODE,
  payload: { mode },
});

export const themeReducer = (state: IThemeSlice = initialState, action: Action): IThemeSlice => {
  const { type, payload } = action;
  switch (type) {
    case CHANGE_MODE:
      return { ...state, palette: { ...state.palette, ...payload } };
    default:
      return state;
  }
};
