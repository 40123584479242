import { Action, ActionCreator } from '../../interface/store-setup/store-interface';
import { TOGGLE_SIDEBAR, SET_TAB_INDEX, SET_ORDER_ID } from '../action-types';

const initialState = {
  sideBarState: false,
  currentTabIndex: 0,
  orderId: '',
};

export type ILayoutSlice = typeof initialState;

export const toggleSideBar = (sideBarState: boolean) => ({
  type: TOGGLE_SIDEBAR,
  payload: { sideBarState },
});

export const setOrderId = (orderId: string) => ({ type: SET_ORDER_ID, payload: { orderId } });

export const showOrderDetail: ActionCreator<string> =
  (orderId = '') =>
  (dispatch) => {
    dispatch(setOrderId(orderId));
    dispatch(setCurrentTab('1'));
  };

export const setCurrentTab = (index: string) => ({
  type: SET_TAB_INDEX,
  payload: { currentTabIndex: parseInt(index) },
});

export const resetOrderDetails: ActionCreator<void> = () => (dispatch) => {
  dispatch(setCurrentTab('0'));
  dispatch(setOrderId(''));
};
export const layoutReducer = (state: ILayoutSlice = initialState, action: Action): ILayoutSlice => {
  const { type, payload } = action;
  switch (type) {
    case TOGGLE_SIDEBAR:
      return { ...state, ...payload };
    case SET_TAB_INDEX:
      return { ...state, ...payload };
    case SET_ORDER_ID:
      return { ...state, ...payload };
    default:
      return state;
  }
};
