import http from '../http-service';
import {
  destroyProductUrl,
  productCategories,
  productsUrl,
  profileUrl,
  singleProductUpload,
  transactionsUrl,
  updateMerchantProfileUrl,
  updateOrderStatusUrl,
  updateProductUrl,
  shippingUrl,
} from '../../utils/endpoints';
import { MerchantProfile, ProductCategoryItem } from '../../store/reducers/merchant-store';
import { checkAndHandleError } from '../http-error-handlers';
import { Notify } from '../../custom-hooks/useNotification';
import { ApiResponse } from '../../interface/api-response/generic';
import {
  ProductsResponse,
  TransactionsResponse,
  UpdatePassword,
  UpdateProfile,
  ShippingResponse,
} from '../../interface/store-setup/merchant-interfaces';

interface ProductCategoriesResponse extends ApiResponse {
  data: {
    count: number;
    records: {
      current_page: number;
      data: ProductCategoryItem[];
      next_page_url: string;
    };
  };
}

interface IProductUpload {
  description: string;
  name: string;
  amount: string;
  discount: string;
  categoryID: string;
  stock: string;
  image: File[];
  publish: boolean;
}

export const fetchProductCategories = async (): Promise<ProductCategoryItem[]> => {
  const categoryList = [];
  try {
    const response = await http.get<ProductCategoriesResponse>(productCategories);
    if (!response)
      throw new Error('An error occured while processing your request. Pleae try again later');
    const error = checkAndHandleError(response);
    if (error) throw error;
    categoryList.push(...response.data.records.data);
  } catch (error: any) {
    throw new Error(error.message);
  }

  return categoryList;
};

interface SingleUploadResponse extends ApiResponse {
  data: any[];
}

export const uploadSingleFile = async (
  product: IProductUpload,
  cleanup: (message: string, type: Notify) => void
) => {
  const formData = new FormData();
  formData.set('description', product.description);
  formData.set('slashed_amount', product.amount);
  formData.set('amount', String(Number(product.amount) - Number(product.discount || 0)));
  formData.set('name', product.name);
  formData.set('category_id', product.categoryID);
  formData.set('stock', product.stock);
  formData.set('image', product.image[0]);
  formData.set('active', product.publish ? '1' : '0');

  try {
    const res = await http.privateMerchantPost<SingleUploadResponse>(singleProductUpload, formData);
    const error = checkAndHandleError(res);
    if (error) throw error;
    cleanup(res?.message || 'Successfull', 'success');
  } catch (error: any) {
    cleanup(error.message || 'An error has occured', 'error');
  }
};

interface ProfileResponse extends ApiResponse {
  data: MerchantProfile[];
}
export const fetchMerchantProfile = async () => {
  try {
    const res = await http.privateMerchantPost<ProfileResponse>(profileUrl, {});
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const updateMerchantProfileService = async (data: UpdateProfile) => {
  // Recast the datatype to temporary relax typescript rules here
  type UpdateProfileTemp = { [x: string]: any };
  const newData: UpdateProfileTemp = data;

  const formData = new FormData();
  for (const [key, value] of Object.entries(newData)) {
    formData.append(key, value);
  }

  try {
    const res = await http.privateMerchantPost<ProfileResponse>(updateMerchantProfileUrl, formData);
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchProducts = async () => {
  try {
    const res = await http.privateMerchantGet<ProductsResponse>(productsUrl);
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const fetchShippingDetails = async () => {
  try {
    const res = await http.privateMerchantGet<ShippingResponse>(shippingUrl);
    // const error = checkAndHandleError(res);
    // if (error) throw error;
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchTransactions = async () => {
  try {
    const res = await http.privateMerchantGet<TransactionsResponse>(transactionsUrl);
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res;
    // return mockTransactions;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

interface ProductDestroyResponse extends ApiResponse {
  data: any[];
}

export const destroyProduct = async (id: string) => {
  try {
    const body = {
      product_id: id,
    };
    const res = await http.privateMerchantPost<ProductDestroyResponse>(destroyProductUrl, body);
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
interface EditProductResponse extends ApiResponse {
  data: any[];
}
export const editProductService = async (body: FormData) => {
  try {
    const res = await http.privateMerchantPost<EditProductResponse>(updateProductUrl, body);
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const updateMerchantPassword = async (body: UpdatePassword) => {
  try {
    const res = await http.privateMerchantPost<EditProductResponse>(updateProductUrl, body);
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const merchantUpdateOrderStatus = async (body: {
  tracking_code: string;
  transref: string | undefined;
}) => {
  try {
    const res = await http.privateMerchantPost(updateOrderStatusUrl, body);
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res;
  } catch (error: any) {
    console.log('error', error.message);
    throw new Error(error.message);
  }
};
