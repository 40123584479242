import { Notify } from '../../custom-hooks/useNotification';
import { LoginCredentials } from '../../interface/components/login';
import encryptStorage from '../storage';
import { merchantStorageKey, storeUserStorageKey } from './local-storage-keys';
interface Result {
  isverified: boolean;
  message: string;
}

export const verifyLoginCredentials = (credentials: LoginCredentials): Result => {
  if (credentials.email && credentials.password) return { isverified: true, message: '' };
  return { isverified: false, message: `Please input a valid username/password` };
};

export const getNameFromResponse = (message: string | undefined): string | void | undefined => {
  return message?.split(' ')[0];
};

export const isUserLoggedIn = (type: typeof merchantStorageKey | typeof storeUserStorageKey) => {
  if (encryptStorage.getItem(type)) return true;
  return false;
};
