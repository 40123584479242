import React, { useMemo, useState } from 'react';
import { SpeedDial, SpeedDialAction, SpeedDialIcon, useTheme } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import ModeNightRoundedIcon from '@mui/icons-material/ModeNightRounded';
import { useDispatch } from 'react-redux';
import { changeMode } from '../../store/reducers/theme-slice';

function QuickLinks() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const shareWhatsapp = () => {
    const message = 'Hello';
    const currentUrl = document.URL;
    window.location.href = `https://api.whatsapp.com/send?text=${message}%20${currentUrl}`;
  };

  // const copyPageLink = () => {
  //   // const copy = CopyToClipboardButton;
  //   const currentUrl = document.URL;
  //   // copy(currentUrl);
  //   return <CopyToClipboardButton text={currentUrl} />;
  // };

  function CopyToClip() {
    const currentUrl = document.URL;
    const handleClick = async () => {
      try {
        await navigator.clipboard.writeText(currentUrl);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    };
    return handleClick();
  }
  const actions = useMemo(
    () => [
      { icon: <ContactSupportOutlinedIcon />, name: 'Contact support' },
      {
        icon: <CopyAllIcon onClick={CopyToClip} />,
        name: 'Copy Page Link',
      },
      { icon: <ShareIcon onClick={shareWhatsapp} />, name: 'Share' },
      {
        icon: <LightModeRoundedIcon onClick={() => dispatch(changeMode('light'))} />,
        name: 'Light mode',
      },
      {
        icon: <ModeNightRoundedIcon onClick={() => dispatch(changeMode('dark'))} />,
        name: 'Dark mode',
      },
    ],
    [dispatch]
  );

  const actionsToUse = actions.filter((action) => {
    const { mode } = theme.palette;
    const { name } = action;
    if (!['Light mode', 'Dark mode'].includes(name)) return true;
    if (mode === 'dark' && name === 'Light mode') return true;
    if (mode === 'light' && name === 'Dark mode') return true;
  });
  return (
    <SpeedDial
      ariaLabel="quick links"
      sx={{ position: 'fixed', bottom: 10, right: 10 }}
      icon={<SpeedDialIcon />}
    >
      {actionsToUse.map((action) => (
        <SpeedDialAction key={action.name} icon={action.icon} tooltipTitle={action.name} />
      ))}
    </SpeedDial>
  );
}

export default QuickLinks;
