import { combineReducers } from 'redux';
import { Action } from '../../interface/store-setup/store-interface';
import { userReducer as userSlice } from './user-slice';
import { layoutReducer as layoutSlice } from './layout-slice';
import { themeReducer as themeSlice } from './theme-slice';
import { merchantSlice } from './merchant-store';
import { shopFrontSlice } from './shopFront';
import { cartSlice } from './cart';

const appReducer = combineReducers({
  userSlice,
  layoutSlice,
  themeSlice,
  merchantSlice,
  shopFrontSlice,
  cartSlice,
});

// eslint-disable-next-line no-use-before-define
const rootReducer = (state: any, action: Action) => {
  return appReducer(state, action);
};

export default rootReducer;
