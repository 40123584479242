import { ApiResponse } from '../../interface/api-response/generic';
import {
  FavoriteProductsResponse,
  ProductListFilters,
  FocusProduct,
  Merchant,
  MerchantProducts,
  MerchantViewResponse,
  StoreProductResponse,
} from '../../interface/api-response/store-front';
import {
  RegisterUserResponse,
  StoreUserProfileResponse,
} from '../../interface/api-response/user-setup';
import { StoreFrontUser } from '../../interface/store-setup/user-slice-interface';
import {
  addToFavoritesUrl,
  getFavoriteProductsUrl,
  listProductsByMerchants,
  merchantViewUrl,
  productDetailsUrl,
  registerUserUrl,
  removeFromFavoritesUrl,
  userLoginUrl,
  userProfileUrl,
} from '../../utils/endpoints';
import { CURRENT_MERCHANT, storeUserStorageKey } from '../../utils/helpers/local-storage-keys';
import encryptStorage from '../../utils/storage';
import { checkAndHandleError } from '../http-error-handlers';
import http from '../http-service';

export const fetchMerchant = async (storeName: string): Promise<Merchant | undefined> => {
  if (!storeName) return;
  let currentMerchant: Merchant = encryptStorage.getItem(CURRENT_MERCHANT);
  if (currentMerchant) {
    const { username, id } = currentMerchant;
    if (username === storeName && id) return currentMerchant;
  }
  try {
    const res = await http.publicPost<MerchantViewResponse>(merchantViewUrl, {
      merchant_id: storeName,
    });
    if (!res) throw new Error('An error has occured');
    const error = checkAndHandleError(res);
    if (error) throw error;
    currentMerchant = res.data[0];
    encryptStorage.setItem(CURRENT_MERCHANT, currentMerchant);
    return currentMerchant;
  } catch (error) {}
};

export const fetchProductsByMerchant = async (params?: ProductListFilters) => {
  try {
    const res = await http.publicGet<MerchantProducts>(listProductsByMerchants, {
      params,
    });
    if (!res) throw new Error('An error has occured');
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res.data.records;
  } catch (error) {}
};

export const fetchProductDetails = async (id: string): Promise<FocusProduct | undefined> => {
  try {
    const res = await http.publicPost<StoreProductResponse>(productDetailsUrl, { product_id: id });
    if (!res) throw new Error('An error has occured');
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res.data;
  } catch (error) {}
};

export const loginStoreUserService = async (user: StoreFrontUser) => {
  try {
    const res = await http.publicPost<RegisterUserResponse>(userLoginUrl, user);
    console.log(res);
    if (!res) throw new Error('An error has occured');
    const error = checkAndHandleError(res);
    if (error) throw error;
    encryptStorage.setItem(storeUserStorageKey, res);
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const registerStoreUserService = async (user: StoreFrontUser) => {
  try {
    const res = await http.publicPost<RegisterUserResponse>(registerUserUrl, {
      ...user,
      name: `${user.firstName} ${user.lastName}`,
    });
    if (!res) throw new Error('An error has occured');
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res.data;
  } catch (error) {
    throw error;
  }
};

export const fetchStoreUserProfile = async () => {
  try {
    const res = await http.privateUserPost<StoreUserProfileResponse>(userProfileUrl, {});
    if (!res) throw new Error('An error has occured');
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res.data[0];
  } catch (error) {
    throw error;
  }
};

export const addToFavoritesService = async (product_id: number) => {
  try {
    const res = await http.privateUserPost<ApiResponse>(addToFavoritesUrl, { product_id });
    if (!res) throw new Error('An error has occured');
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res.message;
  } catch (error) {
    throw error;
  }
};

export const removeFromFavoritesService = async (id: number) => {
  try {
    const res = await http.privateUserPost<ApiResponse>(removeFromFavoritesUrl, {
      favourites: [{ id }],
    });
    if (!res) throw new Error('An error has occured');
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res.message;
  } catch (error) {
    throw error;
  }
};

export const FetchFavoriteProducts = async () => {
  try {
    const res = await http.privateUserGet<FavoriteProductsResponse>(getFavoriteProductsUrl, {});
    if (!res) throw new Error('An error has occured');
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res.data;
  } catch (error) {
    throw error;
  }
};
