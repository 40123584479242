import { LoginCredentials } from '../../interface/components/login';
import { Action, ActionCreator } from '../../interface/store-setup/store-interface';
import { RegisterUser } from '../../interface/store-setup/user-slice-interface';
import { loginUserService } from '../../services/user/login-user';
import { registerUserService } from '../../services/user/register-user';
import { merchantStorageKey } from '../../utils/helpers/local-storage-keys';
import { getNameFromResponse } from '../../utils/helpers/login-helper';
import encryptStorage from '../../utils/storage';
// import { encryptLocalStorage } from '../../utils/storage';
import {
  LOGIN_USER,
  LOGIN_MERCHANT_WITH_STORAGE_DATA,
  LOG_OUT_MERCHANT,
  REGISTER_USER,
  REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATIONS,
  UPDATE_TYPE,
} from '../action-types';
import { getProfile } from './merchant-store';

const notifications: string[] = [];
const initialState = {
  message: '',
  data: { access_token: '', token_type: '' },
  type: {
    isUser: false,
    isMerchant: false,
  },
  notifications,
};

export type IUserSlice = typeof initialState;

export const registerUser: ActionCreator<RegisterUser> =
  (data, notify = () => null) =>
  async (dispatch, getState) => {
    const type = getState().userSlice.type;
    if (!data) return notify('Please provide a request body', 'error');
    try {
      const res = await registerUserService(data, type);
      dispatch({ type: REGISTER_USER, payload: res });
      notify('Registration Successfull', 'success');
    } catch (error: any) {
      const message = error.message || 'An error has occured';
      notify(message, 'error');
    }
  };

export const loginUser: ActionCreator<LoginCredentials> =
  (data, notify = () => null, cleanup = () => null) =>
  async (dispatch) => {
    if (!data) return notify('Please provide a request body', 'error');
    try {
      // check if data is cached
      let user = encryptStorage.getItem(merchantStorageKey);

      // if not cached make a request
      if (!user?.data?.access_token) user = await loginUserService(data);

      dispatch({ type: LOGIN_USER, payload: user });
      notify(`Login successfull. Welcome ${getNameFromResponse(user?.message)}`, 'success');
      cleanup({ success: true });
    } catch (error: any) {
      const message = error.message || 'An error has just occured';
      notify(message, 'error');
    } finally {
      cleanup({ default: true });
    }
  };

export type UpdateType = { isMerchant?: boolean; isUser?: boolean };

export const updateType = (payload: UpdateType) => ({ type: UPDATE_TYPE, payload });
export const resetType = () => ({
  type: UPDATE_TYPE,
  payload: { isUser: false, isMerchant: false },
});

const clearMerchant = () => ({ type: LOG_OUT_MERCHANT, payload: initialState });

export const logoutMerchant: ActionCreator = () => (dispatch) => {
  encryptStorage.setItem(merchantStorageKey, undefined);
  dispatch(clearMerchant());
};

export const loginMerchantWithStorageData = (data: any) => ({
  type: LOGIN_MERCHANT_WITH_STORAGE_DATA,
  payload: data,
});

export const updateNotifications = (data: string[]) => ({
  type: UPDATE_NOTIFICATIONS,
  payload: data,
});

export const removeNotification = (index: number) => ({
  type: REMOVE_NOTIFICATION,
  payload: { index },
});

export const userReducer = (state: IUserSlice = initialState, action: Action): IUserSlice => {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_USER:
      return { ...state, ...payload };
    case LOGIN_USER:
      return { ...state, ...payload };
    case LOGIN_MERCHANT_WITH_STORAGE_DATA:
      return { ...state, ...payload };
    case LOG_OUT_MERCHANT:
      return { ...payload };
    case UPDATE_TYPE:
      return { ...state, type: { ...state.type, ...payload } };
    case UPDATE_NOTIFICATIONS:
      return { ...state, notifications: [...state.notifications, ...payload] };
    case REMOVE_NOTIFICATION:
      const updateNotifications = [...state.notifications];
      updateNotifications.splice(payload.index, 1);
      return { ...state, notifications: updateNotifications };
    default:
      return state;
  }
};
