// import i18next from 'i18next';
// import HttpBackend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import { initReactI18next } from 'react-i18next';

// const apiKey = 'AxcmPw4pxwwvZ8BTEy9Zlg';
// const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

// i18next
//   .use(HttpBackend)
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     fallbackLng: 'en',

//     ns: ['default', 'common', 'home', 'profile', 'userStore'],
//     defaultNS: 'userStore',

//     supportedLngs: ['en', 'fr'],

//     backend: {
//       loadPath: loadPath,
//     },
//   });

import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: [
        'localStorage',
        'path',
        'cookie',
        'htmlTag',
        'querystring',
        'sessionStorage',
        'navigator',
        'subdomain',
      ],
    },
    // lng: localStorage.getItem('i18nextLng'),
    backend: {
      loadPath: '/assets/i18n/{{ns}}/{{lng}}.json',
    },
    fallbackLng: 'en',
    ns: ['common', 'home', 'profile'],

    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    reacts: {
      wait: true,
    },
  });

export default i18n;
