import http from '../http-service';
import { shippingUrl } from '../../utils/endpoints';
import { ShippingResponse } from '../../interface/api-response/deliverymanagement';
import { checkAndHandleError } from '../http-error-handlers';

export const fetchShippingDetails = async (): Promise<any | ShippingResponse> => {
  try {
    const res = await http.publicGet<ShippingResponse>(shippingUrl);
    // const error = checkAndHandleError(res);
    // if (error) throw error;
    //   return res;
    // } catch (error: any) {
    //   throw new Error(error.message);
    // }
    if (!res) throw new Error('An error has occured');
    const error = checkAndHandleError(res);
    if (error) throw error;
    return res;
  } catch (error: any) {
    const message = error.message || 'An internal error has occured';
    throw new Error(message);
  }
};
